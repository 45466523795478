<template>
	<div class="backoffice-view list-view users">
		<div class="page-header">
			<div class="title">
				<h1>Colaboradores</h1>
				<p>Estos son los colaboradores del Sistema de gestión de Tita. Podés agregar uno nuevo, editar su perfil o eliminar uno ya existente.</p>
			</div>
			<router-link
					:to="{name:'BackofficeUserCreate'}"
				>
				<button class="btn btn-primary btn-lg">
					Agregar colaborador
				</button>
			</router-link>
		</div>
		<div
			class="table-container"
		>
			<!-- <div class="table-tools">
				<input type="search" placeholder="Buscar un colaborador" v-model="searchQuery">
			</div> -->
			<div
				v-if="backofficeUsers.length == 0"
			>
				<p>No hay colaboradores.</p>
			</div>
			<div
				class="display-contents flex-h-start flex-h-align-start gap32"
				v-else
			>
				<table>
					<thead>
						<th>Usuario</th>
						<th>Mail</th>
						<th>Rol</th>
						<th>Estado</th>
						<th>Acciones</th>
					</thead>
					<tbody>
						<tr
							v-for="user in filteredData"
							:key="user.backofficeUserId"
						>
							<td>{{ user.username }}</td>
							<td>{{ user.email }}</td>
							<td>{{ roleDescription(user.roleCode) }}</td>
							<td>
								<span class="badge" :class="user.state == 'BLOCKED' ? 'bg-orange' : ''">{{ user.state == 'ENABLED' ? 'Activo' : 'Bloqueado' }}</span>
							</td>
							<td>
								<div class="btn-container">
									
									<router-link
										:to="{
											name: 'BackofficeUserEdit',
											query: {user: user.backofficeUserId}
										}"
									>
										<button>
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<mask id="mask0_6_1147" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
												<rect width="24" height="24" fill="#D9D9D9"/>
												</mask>
												<g mask="url(#mask0_6_1147)">
												<path d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z" fill="#595959"/>
												</g>
											</svg>
										</button>
									</router-link>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="box">
					<p class="body-large semibold">Roles</p>
					<p class="body-small bold">Administrador</p>
					<p class="body-small">Tiene control total sobre las tareas del sistema,  incluyendo la gestión de usuarios y roles (crear, modificar y eliminar cuentas), la edición de cualquier contenido, y la configuración general.</p>
					<br>
					<p class="body-small bold">Editor</p>
					<p class="body-small">Puede gestionar y publicar todas las publicaciones del sitio, incluidas las de otros usuarios.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useContentStore } from '@/stores/index.js'
</script>
<script>
export default {
	name: 'BackofficeUsersList',
	data() {
		return {
			searchQuery: ''
		}
	},

	computed: {
		backofficeUsers() {
			return useContentStore().backofficeUsers
		},

		roles() {
			return useContentStore().roles
		},

		filteredData() {
      return this.backofficeUsers.filter((user) => 
				user.email.toLowerCase().includes(this.searchQuery.toLowerCase())
				|| user.username.toLowerCase().includes(this.searchQuery.toLowerCase()
			));
    },
	},

	methods: {
		roleDescription(roleCode) {
			return this.roles.find(role => role.code == roleCode)?.description
		}
	},

	mounted() {
		useContentStore().fetchBackofficeUsers()
		useContentStore().fetchBackofficeUserRoles()
	},

	updated() {

	}
}
</script>

<style scoped>
.page-header {
	display: flex;
	justify-content: space-between;
}
</style>