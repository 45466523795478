<template>
	<div class="backoffice-view editor-view">
		<div class="page-header flex-v">
			<h1>{{ mode == 'create' ? 'Creá un nuevo artículo' : 'Editar artículo' }}</h1>
			<div class="subheading">
				<p>
					{{ mode == 'create'
						? 'Para publicar un nuevo artículo completá el formulario. Esta es la información que las personas verán en la app.'
						: 'Editá un artículo existente desde el formulario. Esta es la información que las personas verán en la app.'
					}}
				</p>
			</div>
		</div>
		<main class="flex-split">
			<form action="">
				<div class="form-field-vertical">
					<label>Tipo de artículo</label>
					<VueSelect
						v-model="article.articleTypeCode"
						:options="articleTypes.map(t => ({'label': t.description, 'value': t.code}))"
						:isDisabled="mode == 'edit'"
					/>
				</div>
				<div class="form-field-vertical">
					<label>Título</label>
					<input type="text" placeholder="Título" v-model="article.title">
				</div>
				<div class="form-field-vertical">
					<label>Bajada</label>
					<input type="text" placeholder="Bajada" v-model="article.subtitle">
				</div>
				<TipTap
					v-model="article.content"
				/>
				<div
					v-if="article.articleTypeCode === 'POD'"
					class="form-field-vertical"
				>
					<label>Archivo</label>
					<input type="file" placeholder="Archivo" @change="uploadFile()">
				</div>
				<div
					v-if="article.articleTypeCode === 'STO'"
					class="form-field-vertical"
				>
					<label>ID YouTube</label>
					<input type="text" placeholder="" v-model="article.url">
				</div>
				<div
					class="form-field-vertical"
					v-if="articles.length > 0"
				>
					<label>Artículos relacionados</label>
					<VueSelect
						v-model="relatedArticles"
						:options="articles.map(q => ({'label': q.title, 'value': q.articleId}))"
						:is-multi="true"
					/>
				</div>
				<div class="btn-container">
					<button type="button" class="btn btn-primary btn-lg" @click="saveArticle(true)" :disabled="!isValid">Publicar artículo</button>
					<button type="button" class="btn btn-secondary btn-lg" @click="saveArticle(false)" :disabled="!isValid">Guardar borrador</button>
				</div>
			</form>
			<div class="preview">
				<div class="preview-box">
					<div class="box-header">
						<p>Previsualización</p>
					</div>
					<div class="box-content">
						<div class="mockup article" :class="article.articleTypeCode == 'TES' ? 'exams' : article.articleTypeCode == 'PRE' ? 'question' : ''">
							<div class="article-hero">
								<div class="header">
									<h1>{{ article.title }}</h1>
								</div>
							</div>
							<div class="subtitle">
								<p>{{ article.subtitle }}</p>
							</div>
							<div
								class="article-content"
								v-html="article.content"
							></div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script setup>
import TipTap from '@/components/TipTap.vue'
import VueSelect from "vue3-select-component";
import "vue3-select-component/dist/style.css";
import { useContentStore } from '@/stores/index.js'
</script>
<script>
export default {
	name: 'ArticlesEditorView',
	mode: undefined, // 'create' / 'edit'
	components: {
		TipTap,
	},

	data() {
		return {
			article: {
				title: '',
				subtitle: '',
				content: '',
				placement: 0,
				articleTypeCode: '', 
				url: '',
				state: '',
			},
			relatedArticles: [],
			mode: undefined
		}
	},

	computed: {
		articles() {
			return useContentStore().articles
		},

		articleTypes() {
			return useContentStore().articleTypes
		},

		isValid() {
			return this.article.title?.length > 0 
				&& this.article.content?.length > 0 
				&& this.article.articleTypeCode?.length > 0
		},

		questions() {
			return useContentStore().articles.filter(art => art.articleTypeCode == 'PRE')
		}
	},

	methods: {
		saveChanges(data) {
			this.$router.push({name: 'ArticlesListView', query: {'articleId': data.articleId, 'articleTypeCode': data.articleTypeCode}})
		},

		saveArticle(publish) {
			let url, method;
			if (this.mode == 'edit') { method = 'PUT', url = 'update'}
			else if (this.mode == 'create') { method = 'POST', url = 'new' }
			this.article.state = publish ? 'PUBLISHED' : 'DRAFT'
			this.article.user = useContentStore().user.backofficeUserId
			this.article.source = 'BACKOFFICE'
			this.article.ip = '74.207.237.175'
			this.online = true

			fetch(process.env.VUE_APP_URL_API + `api/article/${url}`, {
				method: method,
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
				body: JSON.stringify(this.article)
				})
				.then(response => response.json())
				.then(response => this.saveChanges(response))
				.catch(error => console.error('Error:', error));
			}
	},

	created() {
		if (this.$route.query.id) {
			this.mode = 'edit'
			fetch(process.env.VUE_APP_URL_API + `api/article/getArticleById/${this.$route.query.id}`, {
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
				.then(response => response.json())
				.then(data => this.article = data)
				.catch(error => console.error('Error:', error));
		}

		else {
			this.mode = 'create'
		}

		useContentStore().fetchArticleTypes()
		useContentStore().fetchArticles()
	}
}
</script>

<style scoped>
form {
	flex-grow: 0;
	flex-shrink: 1;
}
</style>
