<template>
	<nav id="sidenav">
		<div id="navigation">
			<ul>
				<router-link
					:to="{name: 'Home'}"
				>
					<li>
						Inicio
					</li>
				</router-link>
				<router-link
					v-if="userRole == 'EDIT'"
					:to="{name: 'ArticlesListView'}"
				>
					<li>
						Artículos
					</li>
				</router-link>
				<router-link
					v-if="userRole == 'ADMIN'"
					:to="{name: 'BackofficeUsersList'}"
				>
					<li>
						Colaboradores
					</li>
				</router-link>
				<router-link
					v-if="userRole == 'ADMIN'"
					:to="{name: 'BackofficeRolesList'}"
				>
					<li>
						Roles
					</li>
				</router-link>
			</ul>
		</div>
	</nav>
</template>

<script>
import { useContentStore } from '@/stores/index.js'
export default {
	name: 'SideNav',
	computed: {
		userRole() {
			return useContentStore().user.roleCode
		}
	}
}
</script>

<style scoped>
#sidenav {
	position: fixed;
	left: 0;
	top: 72px;
	width: 211px;
	flex-shrink: 0;
	height: 100vh;
	background-color: var(--color-n10);
	border-right: 1px solid var(--color-n30);
	padding: 32px 16px;
	display: flex;
	flex-direction: column;
}

#navigation {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

#logo {
	margin-bottom: 48px;
	padding: 0 24px;
}

ul {
	display: flex;
	flex-direction: column;
	gap: 16px;
	list-style-type: none;
}

li {
	border-radius: 8px;
	height: 30px;
	display: flex;
	align-items: center;
	padding-left: 28px;
}

.router-link-active li {
	color: var(--color-g50);
	background-color: var(--color-g10);
	font-weight: 600;
}

a li::before {
	content: '';
	width: 0;
	height: 16px;
	margin-right: 8px;
	border-radius: 3px;
	background-color: var(--color-g50);
	transition: width .3s ease;
}

a.router-link-active li::before {
	width: 4px;
}

a {
	text-decoration: none;
}

a,
a:visited,
a:active {
	color: var(--color-n80);
}

ul + .btn {
	margin-top: auto
}
</style>